import { BodyText, Heading, TextCase } from 'components/atoms/typography';
import React, { useEffect } from 'react';

import { Container } from 'components/atoms/layout/Container';
import useProductReviewsLoader from './loaders/useProductReviewsLoader';
import { useReadable } from 'lib/react-svelte/reactifyStores';
import { ReviewsIoStatus, reviewsIoProductStatusStore } from './loaders/stores';

type ReviewIoWidgetProps = {
  sku: number;
};

const ReviewIoWidget: React.FC<ReviewIoWidgetProps> = ({ sku }) => {
  useProductReviewsLoader();
  const reviewsStatus = useReadable(reviewsIoProductStatusStore);

  useEffect(() => {
    if (reviewsStatus === ReviewsIoStatus.LOADED) {
      import('./widgets/polarisWidget').then(s => s.default(sku));
    }
  }, [reviewsStatus]);

  if (reviewsStatus !== ReviewsIoStatus.LOADED) {
    return null;
  }

  return (
    <Container className="py-rhythm4">
      <Heading level={2} textCase={TextCase.None}>
        Reviews & QA
      </Heading>
      <div className="mb-rhythm1 flex items-center space-x-2">
        <BodyText withoutSpacing>Independently verified by</BodyText>

        <img
          src="https://assets.website-files.com/605c2fd1808171f6c1e88244/615d5c9a37230c5c9f0ec75c_reviewsio-logo.svg"
          alt="Reviews.io"
          className="w-rhythm7"
        />
      </div>
      <div id="ProductReviewsWidget" />
    </Container>
  );
};

export default ReviewIoWidget;
